import firebase from 'api/firebase/firebase';
import { pushURLQuery, getURLQueries } from 'router/UrlQueries';
import * as urlQueryFields from 'router/urlQueryFields';
import * as actionTypes from 'store/actions/actionTypes';
import { selectBoundaryId, openStatisticsDrawer, resetSecondaryBoundaries } from 'store/actions/index';

const GLOBAL_AOI = 'global';

const areasLoadStart = () => ({
	type: actionTypes.LOAD_AREAS_START,
});

const areasLoadSuccess = (areas) => ({
	type: actionTypes.LOAD_AREAS_SUCCESS,
	areas,
});

const areaLoadLayersSuccess = (areaId, areaData) => ({
	type: actionTypes.LOAD_AREA_LAYERS_SUCCESS,
	areaId,
	areaData,
});

const areaLoadLayersStart = () => ({
	type: actionTypes.LOAD_AREA_LAYERS_START,
});

export const loadAreas = () => (dispatch) => {
	dispatch(areasLoadStart());
	firebase.getAreasList().then((areas) => {
		dispatch(areasLoadSuccess(areas));
	});
};

export const selectArea = (areaId, clearFeatureId) => async (dispatch) => {
	pushURLQuery(urlQueryFields.aoi, areaId);
	if (clearFeatureId) {
		pushURLQuery(urlQueryFields.feature, null);
		pushURLQuery(urlQueryFields.boundary, null);
		pushURLQuery(urlQueryFields.map, null);
	}
  dispatch(resetSecondaryBoundaries());
	dispatch(areaLoadLayersStart());
	const config = await firebase.getCustomizationConfig();

	let aoiAreaData;
	if (config && config.useOnlyGlobalLayersAndScripts) {
		aoiAreaData = {}; // if we use only global layers and scripts, we don't need to load the area data
	} else {
		aoiAreaData = await firebase.getArea(areaId);
	}

	// if in the customization configuration there is a globalAoiUsed the use that instead of global!
	let globalAoiUsed = GLOBAL_AOI;
	if( config && config.globalAoiUsed){
		globalAoiUsed = config.globalAoiUsed ;
	}

	if (areaId !== globalAoiUsed && !aoiAreaData.noGlobalLayers) {
		const globalAreaData = await firebase.getArea(globalAoiUsed);

		aoiAreaData.layers = aoiAreaData.layers
			? globalAreaData.layers.concat(aoiAreaData.layers)
			: globalAreaData.layers;
		aoiAreaData.scripts = aoiAreaData.scripts
			? globalAreaData.scripts.concat(aoiAreaData.scripts)
			: globalAreaData.scripts;
	}
	
	dispatch(areaLoadLayersSuccess(areaId, aoiAreaData));
	let statisticsOpenParameterValue = getURLQueries(urlQueryFields.statisticsOpen);
	if (statisticsOpenParameterValue || typeof statisticsOpenParameterValue === 'undefined' || statisticsOpenParameterValue == null) {
		dispatch(openStatisticsDrawer(true)); // Open the statistics unless the user has previously closed it
	}
};
