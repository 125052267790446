import * as actionTypes from 'store/actions/actionTypes';
import { selectTab } from 'store/actions/index';

const drawingModeEnable = (enable) => ({
  type: actionTypes.ENABLE_DRAWING_MODE,
  enable,
});

const boundarySelect = (geoJson) => ({
  type: actionTypes.BOUNDARY_SELECT,
  geoJson,
});

const layerToggle = (layerId) => ({
  type: actionTypes.TOGGLE_MAP_LAYER,
  layerId,
});

const mapClear = () => ({
  type: actionTypes.CLEAR_MAP,
});

const viewPortSet = (boundingBox) => ({
  type: actionTypes.SET_VIEWPORT,
  boundingBox,
});

export const enableDrawingMode = (enable) => (dispatch) => {
  dispatch(drawingModeEnable(enable));
};

export const selectBoundary = (geoJson, isStatisticsHidden) => (dispatch) => {
  dispatch(boundarySelect(geoJson));
  if (!isStatisticsHidden) {
    dispatch(selectTab('Statistics'));
  }
};

export const toggleLayer = (layerId) => (dispatch) => {
  dispatch(layerToggle(layerId));
};

export const clearMap = () => (dispatch) => {
  dispatch(mapClear());
};

export const setViewPort = (placeId) => (dispatch) => {
  dispatch(viewPortSet(placeId));
};
