import { createSelector } from 'reselect';

export const getClickedBoundary = createSelector(
  (state) => state.map.get('clickedBoundary'),
  (clickedBoundary) => clickedBoundary,
);

export const getViewPort = createSelector(
  (state) => state.map.get('viewPort'),
  (boundingBox) => boundingBox,
);

export const getDrawingMode = createSelector(
  (state) => state.map.get('drawingMode'),
  (drawingMode) => drawingMode,
);

export const getVisibleLayers = createSelector(
  (state) => state.map.get('visibleLayers'),
  (visibleLayers) => visibleLayers,
);
